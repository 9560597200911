import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ProductsPanel from './components/Products';
import QuantityPanel from './components/Quantity';
import OrdersPanel from './components/Orders';
import CharacteristicsPanel from './components/Characteristics';
import LoginForm from './components/LoginForm';

function AdminPanel() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  const handleLogin = (userId) => {
    if (userId) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      navigate('/admin/orders');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  if (!isAuthenticated) {
    return <LoginForm onLogin={handleLogin} />;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <button
        onClick={toggleMenu}
        className="md:hidden bg-gray-800 text-white p-2 fixed top-4 left-4 z-50 rounded">
        ☰
      </button>
      <Sidebar onLogout={handleLogout} isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <div
        className="w-full md:w-3/4 p-4 md:p-8 overflow-y-auto"
        onClick={() => isMenuOpen && toggleMenu()}>
        <Routes>
          <Route path="/" element={<Navigate to="/admin/orders" />} />
          <Route path="products" element={<ProductsPanel />} />
          <Route path="quantity" element={<QuantityPanel />} />
          <Route path="orders" element={<OrdersPanel />} />
          <Route path="characteristics" element={<CharacteristicsPanel />} />
        </Routes>
      </div>
    </div>
  );
}

export default AdminPanel;
