import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Block6() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  return (
    <div
      className="relative h-full w-full py-8 lg:py-24 bg-cover text-white flex flex-col justify-center items-center"
      style={{ backgroundImage: 'url("/images/block4.jpg")' }}>
      <div className="w-full bg-black px-4 py-6 lg:px-16 shadow-lg">
        {/* Кнопка для открытия модального окна */}
        <button
          className="bg-white w-full text-start text-black text-xl lg:text-2xl px-4 py-3 lg:px-8 lg:py-4 rounded-lg cursor-pointer"
          onClick={openModal}>
          Почему север и кто такой В.Ф. Марарица
        </button>

        {/* Модальное окно */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Описание"
          className="bg-white text-black max-w-full mx-auto p-8 rounded-lg shadow-lg overflow-y-auto py-8 z-0"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">
          <h2 className="text-2xl lg:text-3xl font-bold mb-4">
            Почему север и кто такой В.Ф. Марарица
          </h2>
          <div className="text-sm lg:text-base leading-relaxed mt-52 lg:mt-72 h-[100vh]">
            <p>
              Эта история началась в середине 2000-х, когда отец моей жены Валерий Фёдорович
              Марарица - человек любознательный, авантюрный и весьма известный в Мурманской области,
              случайно нарыл в библиотеке областной администрации документы 1950-х годов о
              перспективах заполярного пчеловодства. Уникальная географическая особенность
              Заполярья, с точки зрения пчеловодства, заключается в его коротком лете, когда цветёт
              всё и одновременно, ведь богатство и разнообразие медоносов - главный фактор
              насыщенности и целебности мёда. И такого как на Севере невозможно достичь больше
              нигде. Более того, из-за сурового климата в Заполярье отсутствуют многие "пчелиные"
              болезни и паразиты, выкашивающие целые пасеки средней полосы и особенно юга. А тут они
              элементарно не выживают! Казалось бы, живи и радуйся, если бы не одно "но". Пчёлам в
              заполярье также холодно, как и всем остальным. И заставить улей банально пережить
              зиму, которая длится 8 месяцев в году, и не помереть - уже нетривиальная задача.
              Найденные моим тестем документы как раз содержали описание технологий пчеловодства в
              условиях крайнего севера.
            </p>
            <p className="mt-2">
              Как нетрудно догадаться, идея показалась Валерию Фёдоровичу перспективной, и он взялся
              довести её до ума. На старте под проект опытной пасеки "Полярное сияние" удалось
              получить участок земли на окраине посёлка Умба и привлечь частные инвестиции на
              постройку дома и закупку оборудования. А главное - найти человека, который буквально
              растворился в этом проекте: безвылазно месяцами жил на пасеке, лично засеивал
              окрестности медоносными растениями, вёл наблюдения и дневники всего, что только можно,
              и я не удивлюсь, если и разговаривать с пчёлами он тоже научился, но стеснялся
              сказать. Его имя - Геннадий Чударов, и именно его следует считать отцом современного
              заполярного пчеловодства. Следующие десять лет прошли в непрекращающейся и неравной
              борьбе пчеловода со стихией. Ребята экспериментировали с породами пчёл, конструкцией
              ульев и технологий зимовки, внедряли и закрепляли удачные решения. И победили! Им
              удалось добились устойчивого жизненного цикла пасеки, с сохранением популяции в зимний
              период и стабильным "выходом" товарного мёда в летний! Жаль, что Геннадий чуть-чуть не
              дожил до этого момента, его жизнь внезапно прервалась из-за врачебной ошибки. К
              счастью, на смену ему пришёл его помощник Александр Сафонов, который трудится на
              пасеке по сей день и обеспечивает всю производственную часть.
            </p>
            <p className="mt-2">
              Первый заполярный мёд я попробовал в 2021 году. Сказать, что он был вкусным и
              ароматным - это не сказать ничего. Никогда не был любителем мёда, и даже не
              представлял, насколько его вкус может быть богатым и сложным, и при этом не приторным.
              Парадоксально, но именно в этот момент у проекта начались финансовые трудности, и по
              просьбе семьи я начал вкладывать в проект свои деньги, благо основной бизнес работал
              прекрасно и расходы не пугали. Задачи также изменились. Вопрос выживания уже не стоял,
              поэтому согласно планам Валерия Фёдоровича, в ближайшие два-три года требовалось
              расширить "Полярное сияние" до сотни ульев, выйти на промышленные объёмы, тем самым
              превратив опытную пасеку в прибыльное предприятие. На начало сезона 2024 г. пасека
              насчитывала 21 улей, в планах было ежегодно удваивать их. Но, к сожалению, жизнь
              внесла свои коррективы, причём в самой жёсткой форме. 21 июля 2024 в результате
              несчастного случая скоропостижно скончался Валерий Фёдорович. Меня никогда не тянуло к
              земле, и перспектива заниматься пчеловодством вызывала желание покрутить пальцем у
              виска. Но, посетив Умбу в сентябре этого года и обсудив внутри семьи, я принял решение
              не закрывать пасеку, а продолжить дело своего тестя. Весомым аргументом стали 140 кг
              уникального продукта, аналогов которому просто нет в природе! Пасеку я официально
              переименовал в "Полярное сияние им. В.Ф. Марарицы", получил статус фермера в
              Роспотребнадзоре Мурманской области, заказал дизайн торговой марки "Мёд заполярья" и
              этот сайт. С гордостью и от души предлагаю Вам попробовать мой северный мёд!
            </p>
          </div>
          <button className="mt-6 bg-black text-white px-6 py-3 rounded-lg" onClick={closeModal}>
            Закрыть
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default Block6;
