import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar({ onLogout, isOpen, toggleMenu }) {
  return (
    <div
      className={`fixed inset-0 bg-gray-800 text-white h-full w-64 flex flex-col gap-2 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 md:relative md:translate-x-0 z-50`}>
      <h2 className="text-2xl font-bold mb-2 text-center w-full border-b border-gray-700 pb-2">
        Админ Панель
      </h2>
      <Link
        to="orders"
        onClick={toggleMenu}
        className="p-2 px-4 w-full text-left hover:bg-gray-700 rounded transition duration-200">
        Заказы
      </Link>
      <Link
        to="products"
        onClick={toggleMenu}
        className="p-2 px-4 w-full text-left hover:bg-gray-700 rounded transition duration-200">
        Товары
      </Link>
      <Link
        to="quantity"
        onClick={toggleMenu}
        className="p-2 px-4 w-full text-left hover:bg-gray-700 rounded transition duration-200">
        Количество
      </Link>
      <Link
        to="characteristics"
        onClick={toggleMenu}
        className="p-2 px-4 w-full text-left hover:bg-gray-700 rounded transition duration-200">
        Характеристики
      </Link>
      <button
        onClick={() => {
          onLogout();
          toggleMenu();
        }}
        className="mt-auto bg-red-600 text-white w-full text-left px-4 py-2 hover:bg-red-700 rounded transition duration-200">
        Выйти
      </button>
    </div>
  );
}

export default Sidebar;
